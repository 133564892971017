import * as React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../hooks/useSiteMetadata";

const PostMeta = ({ post }: any) => {
  const { title: globalTitle, url } = useSiteMetadata();

  const { title, excerpt, featuredImage } = post;

  return (
    <Helmet>
      {!!excerpt && <meta name="description" content={excerpt} />}
      {!!title && <meta property="og:title" content={title} />}
      {!!excerpt && <meta property="og:description" content={excerpt} />}
      <meta property="og:type" content="article" />
      {!!featuredImage && (
        <meta
          property="og:image"
          content={`${url}${featuredImage.asset.url}`}
        />
      )}
      {!!title && (
        <title>
          {title} | {globalTitle}
        </title>
      )}
    </Helmet>
  );
};

export default PostMeta;
