import { has } from "@datorama/ts-safe-access";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

export interface AuthorProps {
  author: IAuthor;
}

export interface IAuthor {
  name: string;
  role: string;
  bio: string;
  image: {
    asset: {
      // gatsbyImageData: IGatsbyImageData;
      localFile: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    };
  };
}

const Author: React.FC<AuthorProps> = ({ author }) => {
  const { t } = useTranslation(["blog"]);

  return (
    <section>
      <h2>{t("blog:about_the_author")}</h2>
      <div className="flex flex-row items-center space-x-4">
        {has(
          author,
          (author) =>
            author.image.asset.localFile.childImageSharp.gatsbyImageData
        ) ? (
          <GatsbyImage
            // image={author.image.asset.gatsbyImageData}
            image={author.image.asset.localFile.childImageSharp.gatsbyImageData}
            alt={author.name}
            className="w-24 h-24 mx-2 mt-2"
            imgClassName="rounded-full m-0"
          />
        ) : (
          <div className="w-24 h-24 mx-2 mt-2 bg-gray-500 rounded-full" />
        )}
        <div>
          <h6>{author.name}</h6>
          <span className="text-xs font-bold tracking-widest text-gray-500 uppercase">
            {author.role}
          </span>
        </div>
      </div>
      <p>{author.bio}</p>
    </section>
  );
};

export default Author;
